import { useHistory } from "react-router-dom";
import { useEnsureOrgIdPathFn } from "./useEnsureOrgIdPath";

export const useNavigate = () => {
  const history = useHistory();
  const ensureOrgIdPath = useEnsureOrgIdPathFn();

  return (path: string, options?: { replace?: boolean; target?: string }) => {
    const resolvedPath = ensureOrgIdPath(path);
    if (resolvedPath) {
      options?.replace
        ? history.replace(resolvedPath)
        : history.push(resolvedPath);
    } else {
      window.open(path, options?.target || "_self");
    }
  };
};
