export default {
  Query: {
    fetchCurrentListing() {
      try {
        return {
          ...JSON.parse(window.sessionStorage.getItem("listing")),
          __typename: "Listing",
        };
      } catch (_) {
        return null;
      }
    },
  },

  Mutation: {
    stashCurrentListing(_, { listing }) {
      window.sessionStorage.setItem("listing", JSON.stringify(listing));
      return listing;
    },

    clearCurrentListing() {
      window.sessionStorage.removeItem("listing");

      return null;
    },
  },
};
