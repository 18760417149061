import cuid from "cuid";
import * as Sentry from "@sentry/browser";

export const ignoreErrors = [
  // Random plugins/extensions
  "top.GLOBALS",
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  "originalCreateNotification",
  "canvas.contentDocument",
  "MyApp_RemoveAllHighlights",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  // Facebook borked
  "fb_xd_fragment",
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thank @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  "conduitPage",
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  "Script error.",
];

export const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

type SentryInitArg = {
  ENVIRONMENT: string;
  RELEASE: string;
  SENTRY_DSN: string;
};

export function initSentry({
  ENVIRONMENT,
  RELEASE,
  SENTRY_DSN,
}: SentryInitArg) {
  /* Sentry error tracking */
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: RELEASE,

    // https://docs.sentry.io/platforms/javascript/#decluttering-sentry
    ignoreErrors,
    denyUrls,
  });
}

export function getRequestId() {
  const requestId = cuid();
  Sentry.configureScope(function (scope) {
    scope.setTag("request_id", requestId);
  });
  return requestId;
}

type User = {
  id: string;
  affiliations?: Array<{ organisationId: string; status: string }>;
  organisations?: Array<{ id: string }>;
  currentOrganisation?: {
    id: string;
    limits?: { [key: string]: number };
  };
};

export function setSentryUser<ExtUser extends User>(user?: ExtUser | null) {
  if (user) {
    const { id, affiliations, organisations, currentOrganisation } = user;

    // TODO: Revise metadata (e.g. including role?)
    Sentry.configureScope(function (scope) {
      scope.setUser({
        id,
        affiliations: affiliations?.map((a) => ({
          organisationId: a.organisationId,
          status: a.status,
        })),
        organisations: organisations?.map((a) => ({ id: a.id })),
        currentOrganisation: currentOrganisation
          ? { id: currentOrganisation.id, limits: currentOrganisation.limits }
          : undefined,
      });
    });
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }
}

export { Sentry };
