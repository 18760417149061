import React from "react";
import { useAnalytics } from "@compono/analytics";
import { useOrganisation, User, useUser } from "~/auth-layer";

export const AnalyticsIdentifier: React.FC = ({ children }) => {
  const [user] = useUser<User>();
  const [organisation] = useOrganisation();
  const { identify } = useAnalytics();
  if (user) {
    identify(user.id, {
      userId: user.id,
      userType: "employer",
      ...(organisation?.id
        ? {
            organisationId: organisation.id,
            organisationName: organisation.name,
          }
        : {}),
    });
  }
  return <>{children}</>;
};

export default AnalyticsIdentifier;
