import React from "react";

import { apolloClientWithAuth0 as getEmployerClient } from "~/../employer-ui/src/lib/graphql/graphql";
import { apolloClientWithAuth0 as getTrackyrClient } from "~/../trackyr-ui/src/lib/graphql";
import { apolloClientWithAuth0 as getFederationGatewayClient } from "~/lib/federationGateway/client";

import { env } from "~/env";
import { useApolloClient } from "~/hooks/useApolloClient";
import { useRouteOrgId } from "~/hooks/useRouteOrgId";
import { useApolloClients, ApolloClientsContext } from "./ApolloClients";

type ProviderProps = {
  children: React.ReactNode;
};

export const ApolloClientsProvider = ({ children }: ProviderProps) => {
  const { orgId } = useRouteOrgId({ optional: true });

  const employerClient = getEmployerClient();

  const engageClient = useApolloClient({
    uri: `${env.ENGAGE_API_URL}/graphql`,
    orgId,
  });

  const shortlysterClient = useApolloClient({
    uri: `${env.SHORTLYSTER_API_URL}/graphql`,
    orgId,
  });

  const trackyrClient = getTrackyrClient({
    uri: `${env.TRACKYR_API_URL}/graphql`,
    orgId,
  });

  const federationGatewayClient = getFederationGatewayClient();

  return (
    <ApolloClientsContext.Provider
      value={{
        employerClient,
        engageClient,
        shortlysterClient,
        trackyrClient,
        federationGatewayClient,
      }}
    >
      {children}
    </ApolloClientsContext.Provider>
  );
};

export { useApolloClients };
