import { apolloSpy, mixpanel } from "@shortlyster/analytics-kit";
import { USER_LIMIT_ERROR } from "../../features/paywalls/error_details";

export type TrackEvent = (event: apolloSpy.EventData) => void;

type Mapping = {
  [name: string]: TrackEvent;
};

const trackInviteEmployer: TrackEvent = (event) => {
  const { error: { message = "" } = {} } = event;
  if (USER_LIMIT_ERROR === message)
    mixpanel.track("Paywall Triggered - Exceeded User Limit");
};

const trackReactivate: TrackEvent = (event) => {
  const { error: { message = "" } = {} } = event;
  if (USER_LIMIT_ERROR === message)
    mixpanel.track(
      'Paywall Triggered - Exceeded User Limit when click "Reactivate" button'
    );
};

// maps mutation name to event generation function
const mapping: Mapping = {
  InviteEmployer: trackInviteEmployer,
  ReactivateUser: trackReactivate,
};

export default mapping;
