import { NavLink as RRNavLink, NavLinkProps } from "react-router-dom";

import { isExternal, makeLinkComponent } from "@compono/ui";

import { useEnsureOrgIdPath } from "../hooks/useEnsureOrgIdPath";

/*
  Hack alert!

  We use the `data-is-active` prop here as it is allowed to be passed
  through out UI components as a valid HTML attribute. We have to remove
  it here though as a function is not actually a valid `data` attribute.

  We then pass it into React Router `NavLink` as the `isActive` prop.
*/
export const NavLink = makeLinkComponent(({ href, ...restProps }, ref) => {
  const hrefWithOrgId = useEnsureOrgIdPath(href);

  const isActive = (restProps as any)["data-is-active"];
  const props = { ...restProps, ["data-is-active"]: undefined };

  if (isExternal(href)) {
    return <a ref={ref} href={href} {...props} />;
  }

  return (
    <RRNavLink
      ref={ref}
      to={hrefWithOrgId}
      {...(props as Omit<NavLinkProps, "to">)}
      isActive={isActive}
    />
  );
});
