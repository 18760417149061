// https://dev.to/namoscato/graphql-observability-with-sentry-34i6

import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/browser";

export const graphqlErrorLink = onError(
  ({ operation, graphQLErrors, networkError }) => {
    Sentry.withScope((scope) => {
      const context = operation.getContext();
      scope.setTag("request_id", context.headers?.["x-request-id"]);

      scope.setTransactionName(operation.operationName);
      scope.setContext("apolloGraphQLOperation", {
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
      });

      graphQLErrors?.forEach((error) => {
        Sentry.captureMessage(error.message, {
          level: "error" as Sentry.SeverityLevel,
          fingerprint: ["{{ default }}", "{{ transaction }}"],
          contexts: {
            apolloGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions,
            },
          },
        });
      });

      if (networkError) {
        Sentry.captureMessage(networkError.message, {
          level: "error" as Sentry.SeverityLevel,
          contexts: {
            apolloNetworkError: {
              error: networkError,
              extensions: (networkError as any).extensions,
            },
          },
        });
      }
    });
  }
);
