import { mixpanel } from "@shortlyster/analytics-kit";
import { useOrganisation } from "~/auth-layer";
import { get } from "lodash";

type Variables = {
  [index: string]: any;
};

type Mutation = {
  variables: Variables;
};

const trackInvite = (event: Mutation) => {
  const {
    variables: {
      email = undefined,
      role = undefined,
      organisationId = undefined,
    } = {},
  } = event;
  mixpanel.track("Submit Invite Employer", { email, role, organisationId });
};

const trackReactivate = (event: Mutation) => {
  const { variables: { userId = undefined, organisationId = undefined } = {} } =
    event;
  mixpanel.track("Reactivate User", { userId, organisationId });
};

const trackDeactivate = (event: Mutation) => {
  const { variables: { userId = undefined, organisationId = undefined } = {} } =
    event;
  mixpanel.track("Deactivate User", { userId, organisationId });
};

const trackCancelInvite = (event: Mutation) => {
  const { variables: { userId = undefined, organisationId = undefined } = {} } =
    event;
  mixpanel.track("Cancel Invite", { userId, organisationId });
};

const trackUpdateRole = (event: Mutation) => {
  const {
    variables: {
      userId = undefined,
      appAccesses = undefined,
      organisationId = undefined,
    } = {},
  } = event;
  mixpanel.track("Update Role", { userId, appAccesses, organisationId });
};

const trackCreateTeam = (event: Mutation) => {
  const { variables: { name = undefined } = {} } = event;
  mixpanel.track("Create Team", { teamName: name });
};

const trackAddMembers = (event: Mutation) => {
  const { variables: { teamId = undefined, employerIds = [] } = {} } = event;
  mixpanel.track("Add Members", { teamId, employersAdded: employerIds.length });
};

const createIdibuAccount = (event: any) => {
  const [organisation] = useOrganisation();
  const { result } = event;

  mixpanel.track("Job Board Service - Service Registration", {
    clientId: get(result, "createIdibuAccount.clientId", undefined),
    memberId: get(result, "createIdibuAccount.memberId", undefined),
    organisationId: get(organisation, "id", undefined),
  });
};

const deleteJobPost = (event: Mutation) => {
  const [organisation] = useOrganisation();
  const { variables } = event;

  mixpanel.track("Job Board Service - Job Board Post Deletion", {
    jobBoardId: get(variables, "id", undefined),
    advertId: get(variables, "advertId", undefined),
    organisationId: get(organisation, "id", undefined),
  });
};

const unsubscribeFromJobBoard = (event: Mutation) => {
  const [organisation] = useOrganisation();
  const { variables } = event;

  mixpanel.track("Job Board Service - Board Unsubscribe", {
    jobBoardId: get(variables, "portalId", undefined),
    organisationId: get(organisation, "id", undefined),
  });
};

const trackLogoUpload = (event: Mutation) => {
  const { variables } = event;
  const [organisation] = useOrganisation();

  const url = window.URL.createObjectURL(variables.file);
  const image = new Image();
  image.src = url;
  image.onload = () => {
    mixpanel.track("Upload logo", {
      organisationId: get(organisation, "id"),
      fileType: get(variables, "file.type"),
      fileSize: get(variables, "file.size"),
      width: image.width,
      height: image.height,
    });
    window.URL.revokeObjectURL(url);
  };
};

const trackLogoDelete = () => {
  const [organisation] = useOrganisation();

  mixpanel.track("Delete logo", {
    organisationId: get(organisation, "id"),
  });
};

// maps mutation name to event generation function
const mapping = {
  InviteEmployer: trackInvite,
  ReactivateUser: trackReactivate,
  DeactivateUser: trackDeactivate,
  CancelInvite: trackCancelInvite,
  UpdateEmployerRole: trackUpdateRole,
  CreateTeam: trackCreateTeam,
  AddMembers: trackAddMembers,
  UploadLogo: trackLogoUpload,
  DeleteLogo: trackLogoDelete,
  CreateIdibuAccountMutation: createIdibuAccount,
  DeleteJobBoardPost: deleteJobPost,
  DeleteSubscriptionMutation: unsubscribeFromJobBoard,
};

export default mapping;
