import { useUser } from "~/auth-layer";
import { setSentryUser } from "@compono/sentry-browser";

import { User } from "../../shortlyster-ui/src/lib/auth/index";

export const Sentry = <AppUser extends User>({
  children,
}: {
  children: any;
}) => {
  const [user] = useUser<AppUser>();
  setSentryUser<AppUser>(user);
  return children;
};
