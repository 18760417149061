import { apolloSpy } from "@shortlyster/analytics-kit";
import mutationMap from "./mutations";

const hasMutationTracking = (
  eventName: string
): eventName is keyof typeof mutationMap =>
  Object.prototype.hasOwnProperty.call(mutationMap, eventName);

export const watcher = (event: apolloSpy.EventData) => {
  const { name } = event;

  if (event.type === "mutation" && hasMutationTracking(name)) {
    mutationMap[name](event);
  }
};
