import queryMap from "./queries";
import mutationMap from "./mutations";
import errorMap from "./errors";

export const watcher = (event) => {
  const { type, name, success } = event;
  if (type === "mutation") {
    if (!success) return errorMap[name]?.(event);
    return mutationMap[name]?.(event);
  }
  return queryMap[name]?.(event);
};
