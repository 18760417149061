import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { apolloSpy } from "@shortlyster/analytics-kit";
import { getRequestId } from "@compono/sentry-browser";

import { watcher } from "../../shortlyster-ui/src/lib/mixpanel";
import { NormalizedCacheObject } from "@apollo/client";

import { useAuthentication } from "~/hooks/useAuthentication";
import { graphqlErrorLink } from "~/utils/graphqlErrorLink";

export type UseApolloClientProps = {
  uri: string;
  orgId?: string;
};

export const useApolloClient = ({ uri, orgId }: UseApolloClientProps) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuthentication();

  const authLink = setContext(async (_, { headers, ...auth }) => {
    const token = isAuthenticated ? await getAccessTokenSilently() : null;
    return {
      ...auth,
      headers: {
        ...headers,
        ...(orgId && { "x-organisation-id": orgId }),
        ...(token && { authorization: `Bearer ${token}` }),
        "x-request-id": getRequestId(),
      },
    };
  });

  const cache = new InMemoryCache();
  const urlLink = new HttpLink({ uri });

  const baseClient = new ApolloClient({
    link: ApolloLink.from([graphqlErrorLink, authLink, urlLink]),
    cache,
  });

  apolloSpy.configure({ watcher });
  const client = apolloSpy.patch(baseClient);
  return client as ApolloClient<NormalizedCacheObject>;
};
