import { Box, Flex, Loader, LoaderProps } from "@compono/ui";

export type SectionLoaderProps = Partial<LoaderProps>;

export const SectionLoader = ({
  align = "center",
  label = "Loading..",
  size = "lg",
}: SectionLoaderProps) => (
  <Flex
    data-component="SectionLoader"
    sx={{ height: "100%", alignItems: "center" }}
  >
    <Box sx={{ width: "100%" }}>
      <Loader align={align} size={size} label={label} hideLabel />
    </Box>
  </Flex>
);
