import React, { ReactNode } from "react";
import get from "lodash/get";

import { useAuthorization } from "~/hooks/useAuthorization";

import { RequirePermissionError } from "./errors";

type Props = {
  children: ReactNode;
  permission: string;
};

export const RequirePermission = ({ permission, children }: Props) => {
  const auth = useAuthorization();

  if (!auth || !get(auth.permissions, permission))
    throw new RequirePermissionError(permission.split(".").reverse().join(" "));

  return <>{children}</>;
};
