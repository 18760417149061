import * as React from "react";
import { IconProps } from "./types";
export const PortalOutlineIcon = ({ title, ...props }: IconProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    data-ui-icon
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 6.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm-1.5 0a2 2 0 11-4 0 2 2 0 014 0z"
      fill="currentColor"
    />
    <path
      d="M7.75 14c0-.69.56-1.25 1.25-1.25h6c.69 0 1.25.56 1.25 1.25v2a.75.75 0 001.5 0v-2A2.75 2.75 0 0015 11.25H9A2.75 2.75 0 006.25 14v2a.75.75 0 001.5 0v-2zM3.562 17.006a.75.75 0 011.03.255c.158.263.549.648 1.32 1.038.752.382 1.745.706 2.915.921 1.166.215 2.44.31 3.718.272a16.545 16.545 0 003.582-.48.75.75 0 11.373 1.452 18.03 18.03 0 01-3.911.527 19.394 19.394 0 01-4.033-.296c-1.276-.234-2.412-.596-3.323-1.058-.91-.461-1.57-1.01-1.926-1.602a.75.75 0 01.255-1.03zM20 18.75a.75.75 0 100-1.5.75.75 0 000 1.5z"
      fill="currentColor"
    />
  </svg>
);
