import { apolloSpy } from "@shortlyster/analytics-kit";
import queryMap from "./queries";
import mutationMap from "./mutations";
import errorMap from "./errors";

export const watcher = (event: apolloSpy.EventData): void => {
  const { type, name, success } = event;
  if (type === "mutation") {
    if (!success) errorMap[name] && errorMap[name](event);
    else mutationMap[name] && mutationMap[name](event);
  } else {
    queryMap[name] && queryMap[name](event);
  }
};
