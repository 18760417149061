import { apolloSpy, mixpanel } from "@shortlyster/analytics-kit";
import { useOrganisation } from "~/auth-layer";
import { get } from "lodash";
import { cleanGQL } from "../utils";

type TrackEvent = (event: apolloSpy.EventData) => void;

type Mapping = {
  [name: string]: TrackEvent;
};

function arraysEqual(a1: any[], a2: any[]): boolean {
  /* WARNING: arrays must not contain {objects} or behavior may be undefined */
  return JSON.stringify(a1) === JSON.stringify(a2);
}

const isQualUpdate = (event: apolloSpy.EventData): boolean => {
  const keys = Object.keys(event.variables);
  return arraysEqual(["id", "qualifications"], keys.sort());
};

const trackCreateListing: TrackEvent = (event) => {
  const { variables, result } = event;

  const title = get(variables, "details.title", "");
  const sections = get(variables, "details.sections", {});
  const listingId = get(result, "createListing.id", "");

  mixpanel.track("Submit Create Job", {
    listingId,
    title,
    sections,
  });
};

const trackCreateFromTemplate: TrackEvent = (event) => {
  const { variables, result } = event;

  const templateId = get(variables, "templateId", "");
  const listingId = get(result, "createFromTemplate.id", "");
  const title = get(result, "createFromTemplate.title", "");

  mixpanel.track("Submit Create Job From Template", {
    listingId,
    templateId,
    title,
  });
};

const trackUpdateListing: TrackEvent = (event) => {
  if (isQualUpdate(event)) mixpanel.track("Submit Update Qualifications");
  /* discard other updates all section pages have their own analytics */
};

const trackUpdateListingSections: TrackEvent = (event) => {
  const { variables } = event;
  const sections = get(variables, "sections", {});

  mixpanel.track("Submit Manage job", {
    sections,
  });
};

const trackCloseJob: TrackEvent = (event) => {
  const {
    variables: { id },
    result: { title, company },
  } = event;
  mixpanel.track("Closed a Job", { listingId: id, title, company });
};

const trackOpenJob: TrackEvent = (rawEvent) => {
  const event: apolloSpy.EventData = cleanGQL(rawEvent);

  const listingId = get(event, "variables.id", "");
  const company = get(event, "result.openListing.company", "");
  const sections = get(event, "result.openListing.sections", {});

  mixpanel.track("Opened a Job", { company, listingId, sections });
};

const trackUpdateCultureFit: TrackEvent = () => {
  mixpanel.track("Submit Update Culture Fit");
};

const trackUpdateJobFit: TrackEvent = () => {
  mixpanel.track("Submit Update Job Fit");
};

const trackUpdatePersonalityFit: TrackEvent = (event) => {
  const {
    variables: {
      answers: [{ value: type1 }, { value: type2 }],
    },
  } = event;
  mixpanel.track("Submit Update Personality Fit", { type1, type2 });
};

const trackUpdateAssessmentImportance: TrackEvent = () => {
  mixpanel.track("Submit Update Assessment Importances");
};

const trackUpdateSkills: TrackEvent = (event) => {
  const {
    variables: { id, skills },
  } = event;
  mixpanel.track("Submit Update Skills", {
    listingId: id,
    skillsCount: skills.length,
  });
};

const trackDuplicateListing: TrackEvent = (event) => {
  const {
    variables: { id },
    result: { id: duplicatedId, title, company },
  } = event;

  mixpanel.track("Submit Duplicate Job", {
    listingId: id,
    duplicatedId,
    title,
    company,
  });
};

const trackPrescreeningUpdate: TrackEvent = (event) => {
  const {
    variables: { id, ...rest },
  } = event;

  mixpanel.track("Submit pre-screening questions", {
    listingId: id,
    preScreeningCount: get(rest, "preScreeningQuestions.length", 0),
  });
};

const trackCreateJobPosting: TrackEvent = (event) => {
  const [organisation] = useOrganisation();
  const {
    result,
    variables: { data },
  } = event;

  mixpanel.track("Job Board Service - Posting", {
    id: get(result, "createJob.id", undefined),
    listingId: get(data, "listingId", undefined),
    organisationId: get(organisation, "id", undefined),
    boardCount: get(data, "boards", []).length,
    jobBoardIds: get(data, "boards", [])
      .map((e: any) => e?.id)
      .join(","),
  });
};

// maps mutation name to event generation function
const mapping: Mapping = {
  OpenListing: trackOpenJob,
  CreateListing: trackCreateListing,
  CreateFromTemplate: trackCreateFromTemplate,
  CloseListing: trackCloseJob,
  UpdateListing: trackUpdateListing,
  UpdateListingSections: trackUpdateListingSections,
  SaveJobFitResult: trackUpdateJobFit,
  CreateJobPosting: trackCreateJobPosting,
  DuplicateListing: trackDuplicateListing,
  SaveSkillRequirements: trackUpdateSkills,
  SaveCultureFitResult: trackUpdateCultureFit,
  SavePreScreeningQuestions: trackPrescreeningUpdate,
  SavePersonalityFitResult: trackUpdatePersonalityFit,
  SaveAssessmentImportances: trackUpdateAssessmentImportance,
};

export default mapping;
