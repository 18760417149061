import { apolloSpy, mixpanel } from "@shortlyster/analytics-kit";

type TrackEvent = (event: apolloSpy.EventData) => void;

type Mapping = {
  [name: string]: TrackEvent;
};

const trackTopApplicants: TrackEvent = (event) => {
  const {
    variables: { listingId, page },
  } = event;
  mixpanel.track("Fetched Top Applicants", { listingId, page });
};

const trackDroppedApplicants: TrackEvent = (event) => {
  const {
    variables: { listingId, page },
  } = event;
  mixpanel.track("Fetched Dropped Applicants", { listingId, page });
};

// maps query name to event generation function
const mapping: Mapping = {
  TopApplicantsQuery: trackTopApplicants,
  DroppedApplicantsQuery: trackDroppedApplicants,
};

export default mapping;
