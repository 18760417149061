import * as React from "react";
import { IconProps } from "./types";
export const MembershipOutlineIcon = ({ title, ...props }: IconProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    data-ui-icon
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M5 5.75c-.69 0-1.25.56-1.25 1.25v10c0 .69.56 1.25 1.25 1.25h13c.69 0 1.25-.56 1.25-1.25V9a.75.75 0 011.5 0v8A2.75 2.75 0 0118 19.75H5A2.75 2.75 0 012.25 17V7A2.75 2.75 0 015 4.25h11.5a.75.75 0 010 1.5H5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 9.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm-1.5 0a1 1 0 11-2 0 1 1 0 012 0z"
      fill="currentColor"
    />
    <path
      d="M6.75 15.5c0-.69.56-1.25 1.25-1.25h2c.69 0 1.25.56 1.25 1.25v.5a.75.75 0 001.5 0v-.5A2.75 2.75 0 0010 12.75H8a2.75 2.75 0 00-2.75 2.75v.5a.75.75 0 001.5 0v-.5zM15 8.25a.75.75 0 000 1.5h2a.75.75 0 000-1.5h-2zM14.25 12a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zM20 5.75a.75.75 0 100-1.5.75.75 0 000 1.5z"
      fill="currentColor"
    />
  </svg>
);
