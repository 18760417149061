import React from "react";
import { SelectLegacy } from "@compono/ui";
import { useOrganisation } from "~/auth-layer";
import { useNavigate } from "~/hooks/useNavigate";
import styled from "styled-components";
import useOrgs from "../../employer-ui/src/features/layout/Header/useOrgs";

const TopNavWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
  background-color: white;
`;

const SelectWrapper = styled.div`
  width: 241px;
  margin-left: auto;
  margin-right: 0;
  z-index: 1;
`;

export const TopNav: React.FC = () => {
  const [currentOrg] = useOrganisation();
  const navigate = useNavigate();
  const currentOrgId = currentOrg && currentOrg.id;

  const { options } = useOrgs();
  const organisations = options?.map((option) => ({
    ...option,
    selected: option.value === currentOrgId,
  }));

  return (
    <TopNavWrapper>
      <SelectWrapper>
        {currentOrgId && options && (
          <SelectLegacy
            dropdownZIndex="var(--z-index-layoutHeader)"
            onChange={(option) => {
              if (!Array.isArray(option) && currentOrgId !== option.value) {
                navigate(
                  `${window.location.origin}/organisation/${option.value}`
                );
              }
            }}
            options={organisations}
          />
        )}
      </SelectWrapper>
    </TopNavWrapper>
  );
};
