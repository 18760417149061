import React, { useEffect, useState } from "react";

import { createContext } from "@compono/ui";
import { useHistory } from "react-router";

export type RouteOrgIdContext = { orgId: string };

const [useRouteOrgId, RouteOrgIdContext] = createContext<RouteOrgIdContext>({
  name: "RouteOrgId",
});

export const orgIdRegExp = new RegExp(
  `^/organisation/([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})`
);

type ProviderProps = {
  children: React.ReactNode;
};

/*
  Provides access to the organisation id in the location bar without triggering
  updates every time the url changes as react-router utils do.

  ie the context value will only change when the organisation id in the location
  bar changes
*/
export const RouteOrgIdProvider = ({ children }: ProviderProps) => {
  const history = useHistory();
  const { pathname } = history.location;
  const match = pathname.match(orgIdRegExp);
  const [orgId, setOrgId] = useState(match?.[1] || "");

  useEffect(
    () =>
      history.listen(() => {
        const { pathname } = history.location;
        const match = pathname.match(orgIdRegExp);
        if (match?.[1] !== orgId) {
          setOrgId(match?.[1] || "");
        }
      }),
    []
  );

  return (
    <RouteOrgIdContext.Provider value={{ orgId }}>
      {children}
    </RouteOrgIdContext.Provider>
  );
};

export { useRouteOrgId };
