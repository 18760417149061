import { envSchema } from "./envSchema";

export const getRuntimeEnv = () => {
  const userAgent = navigator?.userAgent;
  const HEADLESS_TESTING =
    userAgent.includes("HeadlessChrome/") ||
    userAgent.includes("Playwright") ||
    userAgent.includes("E2E");

  return { HEADLESS_TESTING };
};

const envVars = Object.assign({}, __PROCESS_ENV__, getRuntimeEnv());

const result = envSchema.safeParse(envVars);

if (result.success === false) {
  console.error("envSchema.safeParse", result.error);
  throw new Error("Invalid configuration");
}

export const env = {
  ...result.data,
  MIXPANEL_API_URL: `https://${
    result.data.MIXPANEL_PROXY_DOMAIN || "api.mixpanel.com"
  }`,
  MIXPANEL_CDN_URL: result.data.MIXPANEL_PROXY_DOMAIN
    ? `//${result.data.MIXPANEL_PROXY_DOMAIN}/libs/mixpanel-2-latest.min.js`
    : undefined,
};
