import * as React from "react";
import { IconProps } from "./types";
export const CandidatesOutlineIcon = ({ title, ...props }: IconProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    data-ui-icon
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 11a4 4 0 100-8 4 4 0 000 8zm0-1.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
      fill="currentColor"
    />
    <path
      d="M4.75 15c0-.69.56-1.25 1.25-1.25h6c.69 0 1.25.56 1.25 1.25v1a.75.75 0 001.5 0v-1A2.75 2.75 0 0012 12.25H6A2.75 2.75 0 003.25 15v5a.75.75 0 001.5 0v-5zM17.5 12.25a.75.75 0 000 1.5h.5c.69 0 1.25.56 1.25 1.25v1a.75.75 0 001.5 0v-1A2.75 2.75 0 0018 12.25h-.5zM14.75 20a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM20 20.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 4.5a2.5 2.5 0 010 5V11a4 4 0 100-8v1.5z"
      fill="currentColor"
    />
  </svg>
);
