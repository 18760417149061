export const USER_LIMIT_ERROR =
  "GraphQL error: You have reached your limit of users on your plan.";

type PaywallError = {
  error?: Error;
};

// eslint-disable-next-line consistent-return
export const paywallErrorHandler = ({ error }: PaywallError) => {
  if (!error) return null;
  const { message } = error;

  if (message === USER_LIMIT_ERROR) {
    return {
      title:
        "Sorry, it looks like you have reached the limit of users on your plan.",
      actions: [
        {
          description:
            "Want to add additional users? Contact your account manager directly or email customersuccess@compono.com to increase your user limit.",
        },
      ],
    };
  }
  return null;
};
