import { ShowIf, ShowIfAny, User } from "~/components/auth";
import { useAuthorization } from "~/hooks/useAuthorization";

export { RequireOrganisationError, RequirePermission } from "~/components/auth";
export type { Employer, Organisation, User } from "~/components/auth";

export const useEmployerAuth = () => {
  const auth = useAuthorization();
  return [auth] as const;
};

export const useOrganisation = () => {
  const { organisation } = useAuthorization();
  return [organisation] as const;
};

export const usePermissions = () => {
  const { permissions } = useAuthorization();
  return [permissions] as const;
};

export const useUser = <U = User>() => {
  const { user } = useAuthorization();
  return [user as U] as const;
};

export { ShowIf, ShowIfAny };
