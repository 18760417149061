import React, { useEffect } from "react";
import { ApolloClient, InMemoryCache, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory, useParams } from "react-router-dom";

import { env } from "../src/env";
import { useSearchParams } from "../src/hooks/useSearchParams";

import { GetPending } from "./src/auth0/queries";
import { redirect } from "react-router-dom-v5-compat";

const myClient = new ApolloClient({
  uri: `${env.ENGAGE_API_URL}/openGraphql`,
  cache: new InMemoryCache(),
});

export const PublicJoin = () => {
  const { loginWithRedirect } = useAuth0();
  const history = useHistory();
  const params = useParams<{ orgId: string }>();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const goToLogin = () =>
    loginWithRedirect({
      appState: {
        returnTo: `${location?.pathname}${location?.search}`,
      },
    });

  const { data, error } = useQuery<{ pendingUser?: any }>(GetPending, {
    client: myClient,
    skip: !token,
    variables: {
      token,
      orgId: params.orgId,
    },
  });

  useEffect(() => {
    if (data) {
      if (data.pendingUser) {
        const redirectUrl = window.location.href.split("?token")[0];
        history.push(`/join?inviteToken=${token}&redirectUrl=${redirectUrl}`);
      } else {
        goToLogin();
      }
    }
  }, [data]);

  useEffect(() => {
    if (!token || error) {
      goToLogin();
    }
  }, []);

  return null;
};
