import * as React from "react";
import { IconProps } from "./types";
export const SignInOutlineIcon = ({ title, ...props }: IconProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    data-ui-icon
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M9.5 3.75a.75.75 0 00-.75.75V8a.75.75 0 01-1.5 0V4.5A2.25 2.25 0 019.5 2.25h7a2.25 2.25 0 012.25 2.25V19a2.25 2.25 0 01-2.25 2.25h-7A2.25 2.25 0 017.25 19v-3a.75.75 0 011.5 0v3c0 .414.336.75.75.75h7a.75.75 0 00.75-.75V4.5a.75.75 0 00-.75-.75h-7zM2.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
      fill="currentColor"
    />
    <path
      d="M11.53 8.47a.75.75 0 10-1.06 1.06l1.72 1.72H6a.75.75 0 000 1.5h6.19l-1.72 1.72a.75.75 0 101.06 1.06l3-3a.75.75 0 000-1.06l-3-3z"
      fill="currentColor"
    />
  </svg>
);
