import { apolloSpy, mixpanel } from "@shortlyster/analytics-kit";

export const LIMIT_ERR =
  /GraphQL error: You have reached the limit of \d+ open listings on this plan/;

export type TrackEvent = (event: apolloSpy.EventData) => void;

type Mapping = {
  [name: string]: TrackEvent;
};

const trackOpenJob: TrackEvent = (event) => {
  const { error: { message = "" } = {} } = event;

  if (LIMIT_ERR.test(message)) {
    mixpanel.track("Popup Triggered - Exceeded Job Limit");
  }
};

// maps mutation name to event generation function
const mapping: Mapping = {
  OpenListing: trackOpenJob,
};

export default mapping;
