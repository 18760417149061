export default `
  type Query {
    currentUser: User
    fetchCurrentListing: Listing
  },
  type Mutation {
    stashCurrentListing(listing: Object!): Listing
    clearCurrentListing: Listing
  }
`;
