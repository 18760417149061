import React from "react";
import get from "lodash.get";
import merge from "ts-deepmerge";

import { useAuthorization } from "~/hooks/useAuthorization";

type ShowIfAnyProps = {
  allowedTo: string[];
  children?: React.ReactNode;
  ifNotRender?: React.ReactNode;
  permissions?: Record<string, Record<string, boolean>>;
};

/**
 * Conditional rendering depending on permission in user's organisation profile
 * @param {string[]} allowedTo - array of paths to permissions, like ["listings.create"]
 * @param {ReactNode} ifNotRender - function returning ReactElement to render when
 *        permission condition is not met
 * @param {Permissions} permissions - additional permission object to override permissions from
 *        user profile, for example specified on resource to be accessed
 *
 * @return {ReactElement}
 */
export const ShowIfAny = (props: ShowIfAnyProps) => {
  const { allowedTo, ifNotRender, children, permissions } = props;

  const auth = useAuthorization();

  const allPermissions = permissions
    ? merge(auth?.permissions, permissions)
    : auth?.permissions;

  const ok = allowedTo.find((p) => get(allPermissions, p));

  return <>{ok ? children : ifNotRender}</>;
};
