import gql from "graphql-tag";

export const FetchEmployerProfile = gql`
  query FetchEmployerProfile($organisationId: ID) {
    profile {
      id
      email
      firstName
      lastName
    }
    permissions {
      organisations {
        edit
        manageApiKeys
        manageHrIntegrations
      }
      employers {
        view
      }
      listings {
        create
        viewDrafts
      }
      teams {
        view
      }
      jobBoards {
        manageAccount
        publish
        manageSubscription
      }
      applicationMessages {
        view
      }
      isComponoAdmin
    }
    recruiterProfile {
      id
      suspended
      affiliations {
        organisationId
        status
      }
      accesses {
        role
        organisationId
        allAppsRoles {
          appCode
          roles
        }
      }
      organisations {
        id
        name
      }
      currentOrganisation(organisationId: $organisationId) {
        id
        name
        metadata {
          source
        }
        products {
          productCode
          limits {
            limitCode
            limit
          }
        }
      }
    }
  }
`;

export type TrackyrPermissions = {
  organisations: {
    edit: boolean;
    manageApiKeys: boolean;
    manageHrIntegrations: boolean;
    hasWorkforceAssessment: boolean;
  };
  employers: { view: boolean };
  listings: { create: boolean };
  teams: { view: boolean };
  jobBoards: { publish: boolean };
  applicationMessages: {
    create: boolean;
    view: boolean;
  };
  calendarEvents: {
    create: boolean;
    view: boolean;
  };
  canSyncCandidatesToHris: boolean;
};

export const FetchTrackyrPermissions = gql`
  query FetchTrackyrPermissions {
    permissions {
      listings {
        create
      }
      organisations {
        edit
        manageApiKeys
        manageHrIntegrations
        hasWorkforceAssessment
      }
      employers {
        view
      }
      teams {
        view
      }
      applicationMessages {
        create
        view
      }
      jobBoards {
        manageSubscription
        publish
      }
      calendarEvents {
        view
        create
      }
      canSyncCandidatesToHris
    }
  }
`;

export type EmployerPermissions = {
  organisations: {
    edit: boolean;
    manageApiKeys: boolean;
    manageHrIntegrations: boolean;
  };
  employers: { view: boolean };
  listings: { create: boolean; viewDrafts: boolean; view: boolean };
  teams: { view: boolean };
  applicationMessages: { view: boolean };
  hireReports: { view: boolean };
  jobBoards: { manageSubscription: boolean };
  isAbleToManageTags: boolean;
};

export const FetchEmployerPermissions = gql`
  query FetchEmployerPermissions {
    permissions {
      organisations {
        edit
        manageApiKeys
        manageHrIntegrations
      }
      employers {
        view
      }
      listings {
        create
        viewDrafts
        view
      }
      teams {
        view
      }
      jobBoards {
        manageAccount
        publish
        manageSubscription
      }
      applicationMessages {
        view
      }
      hireReports {
        view
      }
      isAbleToManageTags
    }
    recruiterProfile {
      accesses {
        role
        organisationId
        allAppsRoles {
          appCode
          roles
        }
      }
    }
  }
`;

export type EngagePermissions = {
  permissions: string[];
};

export const FetchEngagePermissions = gql`
  query FetchEngagePermissions($orgId: String) {
    permissions(orgId: $orgId) {
      permissions
    }
  }
`;
