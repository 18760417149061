// import { mixpanel } from '@shortlyster/analytics-kit';

// const trackTopApplicants = event => {
//   const {
//     variables: { listingId, page },
//   } = event;
//   mixpanel.track('Fetched Top Applicants', { listingId, page });
// };

// const trackDroppedApplicants = event => {
//   const {
//     variables: { listingId, page },
//   } = event;
//   mixpanel.track('Fetched Dropped Applicants', { listingId, page });
// };

// maps query name to event generation function
const mapping = {};

export default mapping;
