import React from "react";
import { useOrganisation, User, useUser } from "~/auth-layer";
import { LDProvider, useLDClient } from "launchdarkly-react-client-sdk";
import { env } from "~/env";

export const LaunchDarklyProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  if (env.HEADLESS_TESTING) {
    return (
      <LDProvider
        clientSideID={env.LAUNCH_DARKLY_CLIENT_ID}
        deferInitialization
      >
        {children}
      </LDProvider>
    );
  }

  return (
    <LDProvider
      clientSideID={env.LAUNCH_DARKLY_CLIENT_ID}
      context={{
        anonymous: true,
        key: "unauthenticated-employer",
        custom: {
          environment: env.ENVIRONMENT,
        },
      }}
    >
      {children}
    </LDProvider>
  );
};

export const LaunchDarklyIdentifier: React.FC = ({ children }) => {
  if (env.HEADLESS_TESTING) {
    return <>{children}</>;
  }

  const [user] = useUser<User>();
  const [organisation] = useOrganisation();
  const ldClient = useLDClient();
  if (user && organisation) {
    const organisationId = organisation.id;
    const userId = user.id;

    ldClient?.waitUntilReady().then(() => {
      ldClient.identify({
        kind: "multi",
        user: { key: userId },
        organisation: {
          key: organisationId,
          name: organisation.name,
        },
      });
    });
  }

  return <>{children}</>;
};
