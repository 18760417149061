import * as React from "react";
import { IconProps } from "./types";
export const SettingsOutline2Icon = ({ title, ...props }: IconProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    data-ui-icon
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M10.75 10.13A2.25 2.25 0 0112 9.75v-1.5A3.75 3.75 0 1015.75 12h-1.5a2.25 2.25 0 11-3.5-1.87z"
      fill="currentColor"
    />
    <path d="M14 11a1 1 0 100-2 1 1 0 000 2z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 2.25A2.25 2.25 0 009 4.5v.255l-.102-.101a2.25 2.25 0 00-3.182 0l-1.06 1.06a2.25 2.25 0 000 3.183L4.759 9H4.5a2.25 2.25 0 00-2.25 2.25v1.5A2.25 2.25 0 004.5 15h.417l-.262.262a2.25 2.25 0 000 3.182l1.06 1.06a2.25 2.25 0 003.183 0L9 19.403v.098a2.25 2.25 0 002.25 2.25h1.5A2.25 2.25 0 0015 19.5v-.26l.262.263a2.25 2.25 0 003.182 0l1.061-1.06a2.25 2.25 0 000-3.182l-.26-.261h.255a2.25 2.25 0 002.25-2.25v-1.5A2.25 2.25 0 0019.5 9h-.098l.102-.102a2.25 2.25 0 000-3.182l-1.06-1.06a2.25 2.25 0 00-3.182 0L15 4.916V4.5a2.25 2.25 0 00-2.25-2.25h-1.5zM10.5 4.5a.75.75 0 01.75-.75h1.5a.75.75 0 01.75.75v1.475a.75.75 0 00.487.703c.16.06.315.125.466.198a.75.75 0 00.855-.146l1.014-1.014a.75.75 0 011.061 0l1.06 1.06a.75.75 0 010 1.061L17.37 8.912a.75.75 0 00-.158.829c.04.09.076.18.11.271a.75.75 0 00.703.488H19.5a.75.75 0 01.75.75v1.5a.75.75 0 01-.75.75h-1.477a.75.75 0 00-.703.487 5.666 5.666 0 01-.152.368.75.75 0 00.152.842l1.124 1.124a.75.75 0 010 1.06l-1.06 1.062a.75.75 0 01-1.06 0l-1.125-1.125a.75.75 0 00-.842-.151 5.617 5.617 0 01-.37.153.75.75 0 00-.487.703V19.5a.75.75 0 01-.75.75h-1.5a.75.75 0 01-.75-.75v-1.477a.75.75 0 00-.488-.703 5.55 5.55 0 01-.27-.11.75.75 0 00-.83.159l-1.075 1.075a.75.75 0 01-1.06 0l-1.061-1.06a.75.75 0 010-1.061l1.015-1.016a.75.75 0 00.146-.854 5.628 5.628 0 01-.199-.466.75.75 0 00-.702-.487H4.5a.75.75 0 01-.75-.75v-1.5a.75.75 0 01.75-.75h1.475a.75.75 0 00.703-.488 5.64 5.64 0 01.154-.37.75.75 0 00-.152-.843l-.964-.963a.75.75 0 010-1.06l1.061-1.061a.75.75 0 011.06 0l.965.963a.75.75 0 00.841.152c.121-.055.244-.106.37-.153a.75.75 0 00.487-.702V4.5z"
      fill="currentColor"
    />
  </svg>
);
