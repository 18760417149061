import { ApolloProvider } from "@apollo/client";
import React from "react";
import { BrowserRouter, Link } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import type { AnalyticsPlugin } from "analytics";
import { AnalyticsProvider } from "@compono/analytics";
import mixpanelPlugin from "@analytics/mixpanel";
import {
  UIProvider,
  createTheme,
  isExternal,
  makeLinkComponent,
} from "@compono/ui";

import { env } from "~/env";
import { useEnsureOrgIdPath } from "~/hooks/useEnsureOrgIdPath";
import { useApolloClients } from "~/hooks/useApolloClients";

import { EnsureLocationOrgId } from "./EnsureLocationOrgId";
import { AuthenticationProvider } from "./auth/AuthenticationProvider";
import { ApolloClientsProvider } from "./ApolloClientsProvider";
import { RouteOrgIdProvider } from "./RouteOrgIdProvider";
import { LaunchDarklyProvider } from "./LaunchDarkly";

const customTheme = createTheme({
  "box-background": "white",
});

const CustomLink = makeLinkComponent(({ href, ...restProps }, ref) => {
  const hrefWithOrgId = useEnsureOrgIdPath(href);
  if (!href) {
    return (
      <a ref={ref} href="" {...restProps} onClick={(e) => e.preventDefault()} />
    );
  }

  if (isExternal(href)) {
    return <a ref={ref} href={href} {...restProps} />;
  }

  return <Link ref={ref} to={hrefWithOrgId} {...restProps} />;
});

const SlApolloProvider = ({ children }: { children: React.ReactNode }) => {
  const clients = useApolloClients();
  return (
    <ApolloProvider client={clients.shortlysterClient}>
      {children}
    </ApolloProvider>
  );
};

let plugins: AnalyticsPlugin[] = [];

if (env.APP_SEND_ANALYTICS && !env.HEADLESS_TESTING) {
  plugins = [
    ...plugins,
    mixpanelPlugin({
      token: env.MIXPANEL_TOKEN,
      options: {
        api_host: env.MIXPANEL_API_URL,
      },
    }),
  ];
}

export const Providers = ({ children }: { children?: React.ReactNode }) => {
  return (
    <AnalyticsProvider appName="platform-ui" plugins={plugins}>
      <BrowserRouter>
        <RouteOrgIdProvider>
          <CompatRouter>
            <AuthenticationProvider
              domain={env.AUTH0_CUSTOM_DOMAIN}
              clientId={env.AUTH0_SPA_CLIENT_ID}
              envName={env.AUTH0_ENV_NAME}
            >
              <EnsureLocationOrgId>
                <ApolloClientsProvider>
                  <LaunchDarklyProvider>
                    <UIProvider linkComponent={CustomLink} theme={customTheme}>
                      <SlApolloProvider>{children}</SlApolloProvider>
                    </UIProvider>
                  </LaunchDarklyProvider>
                </ApolloClientsProvider>
              </EnsureLocationOrgId>
            </AuthenticationProvider>
          </CompatRouter>
        </RouteOrgIdProvider>
      </BrowserRouter>
    </AnalyticsProvider>
  );
};
