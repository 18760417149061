/* eslint-disable max-classes-per-file */
export class AuthError extends Error {}

export class RequireOrganisationError extends AuthError {
  constructor(message = "Organisation is required") {
    super(message);
  }
}

export class RequireUserError extends AuthError {
  constructor(message = "A signed in user is required") {
    super(message);
  }
}
export class RequirePermissionError extends AuthError {
  constructor(permission = "not specified") {
    super(`Current user must have a permission to ${permission}`);
  }
}
