import find from "lodash/find";
import sortBy from "lodash/sortBy";
import values from "lodash/values";

/**
 * Get an ISO date string (without time or time zone) from a given date
 */
export function dateString(date: any): string {
  const refDate = date instanceof Date ? date : new Date(date);
  const pad = (num: number): string => {
    const str = num.toString();
    if (str.length < 2) {
      return `00${str}`.substr(-2);
    }
    return str;
  };
  return [
    refDate.getFullYear(),
    pad(refDate.getMonth() + 1),
    pad(refDate.getDate()),
  ].join("-");
}

/**
 * Find an object by its id in a standard schema list
 */
export function findById(list: any[], id: string): any {
  return find(list || [], { id });
}

/**
 * Sort a standard schema list.
 * Sorts by `name` by default, or another field name if provided.
 */
export function sortSchemaList(list: any[], field = "name"): any[] {
  return sortBy(list || [], field);
}

/**
 * Turns an array of values into an array of Objects that have { value, label }
 */
export function listToOptions(list: Array<string | number | boolean>): any[] {
  return list.map((val) => ({ value: val, label: val }));
}

/**
 * Turns an array of objects into an array suitable for react-select options
 */
export function modelsToOptions(list: any[], labelKey?: string): any[] {
  return list.map((model) => ({
    value: model.id,
    label: model[labelKey || "name"],
    object: model,
  }));
}

/**
 * Removes the item in an array at the index.
 * (['a', 'b', 'c'], 2) => ['a', 'b']
 */
export function removeIndex(arr: Array<Object | string>, index: number) {
  return arr.slice(0, index).concat(arr.slice(index + 1));
}

const numberFields = ["years", "importance"];
const booleanFields = ["currentJob"];
const isNumberField = (fieldName: any) => numberFields.indexOf(fieldName) > -1;
const isBooleanField = (fieldName: any) =>
  booleanFields.indexOf(fieldName) > -1;

function convertValue(fieldName: string, value: any) {
  if (isNumberField(fieldName)) {
    return +value || 0;
  }
  if (isBooleanField(fieldName)) {
    return value === "false" ? false : !!value;
  }
  return value;
}

function fillEmpty(array: any[], obj: any) {
  const arr = array.slice();
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === undefined) {
      arr[i] = obj;
    }
  }
  return arr;
}

/**
 * Merges the current fields with the changed field to be usable in plain object
 * form, for example...
 * ({ name: '', title: 'Horse Person' }, { field: 'name', value: 'Garry'}) =>
 *   { name: 'Garry', title: 'Horse Person' }
 * */
export function mergeFields(fields: any, payload: any) {
  const { field, value, index } = payload;
  if (index !== undefined) {
    const arr: any[] = values(fields);
    if (field) {
      let mergeObj;
      if (
        index !== undefined &&
        fields[index] &&
        fields[index][field] instanceof Array
      ) {
        mergeObj = fields[index][field];
      } else {
        mergeObj = { ...fields[field] };
      }
      arr[index] = {
        ...(arr[index] || {}),
        [field]:
          value !== undefined
            ? convertValue(field, value)
            : mergeFields({ ...mergeObj }, payload.sub),
      };
      return fillEmpty(arr, {});
    }
    arr[index] =
      value !== undefined
        ? convertValue(field, value)
        : mergeFields({ ...fields[index] }, payload.sub);
    return fillEmpty(arr, "");
  }
  if (payload.sub) {
    return {
      ...fields,
      [field]: mergeFields({ ...fields[field] }, payload.sub),
    };
  }
  return { ...fields, [field]: convertValue(field, value) };
}

/**
 * A simple function to extract data from a form
 */
// export function formToParams(form: HTMLFormElement) {
//   const params = {};

//   for (let i = 0; i < form.elements.length; i++) {
//     const input: Object = form.elements[i];
//     if (input.name) params[input.name] = input.value;
//   }

//   return params;
// }

/**
 * Returns a number rounded to whatever decimal point you ask for "better" than
 * toFixed(x) because it always returns a number, and doesn't append zeroes
 */
export function shortRound(num: number, dec: number) {
  const factor = 10 ** dec;
  const tempNumber = num * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
}

export const calculatePercentage = (value: number, outOf: number): number =>
  Math.round((value / (outOf || 1)) * 100);

export const addApostrophy = (
  word: string // eslint-disable-next-line no-nested-ternary
) => (word ? (word.slice(-1) === "s" ? `${word}'` : `${word}'s`) : "");

/**
 * Returns a copy of an object including only the specified keys
 * e.g. pick({ a: 1, b: 2, c: 3 }, ['c']) => { c: 3 }
 */
export function pick(obj, keys) {
  const sourceKeys = Object.keys(obj);

  return keys.reduce((result, key) => {
    if (sourceKeys.includes(key)) result[key] = obj[key];
    return result;
  }, {});
}

/**
 * Remove keys where value is null or undefined
 * e.g. pickDefined({ a: null, b: 2, c: undefined }) => { b: 2 }
 */
export function pickDefined(obj) {
  return Object.keys(obj).reduce((result, key) => {
    if (obj[key] != null) result[key] = obj[key];
    return result;
  }, {});
}

/* Iterate a fixed number of times (see lodash.times) */
export const times = <Result>(
  count: number,
  fn: (x: number) => Result
): Result[] => Array(...Array(count)).map((_, i) => fn(i));

/** camelCaseWords
 * remove separator and capitalise subsequent words
 */
export const camelCaseWords = (string: string, separator = " ") =>
  string &&
  string
    .split(separator)
    .map((word, i) => (i ? word[0].toUpperCase() + word.slice(1) : word))
    .join("");

/**
 * asc
 * sorts array by field in ascending value order
 *
 * eg:
 * [{stat: 5}, {stat: 2}, {stat: 1}].sort(asc('stat'))
 */
export const asc = (field: string) => (a, b) => a[field] < b[field] ? -1 : 1;

/**
 * desc
 * sorts array by field in descending value order
 *
 * eg:
 * [{stat: 1}, {stat: 5}, {stat: 3}].sort(desc('stat'))
 */
export const desc = (field: string) => (a, b) => a[field] < b[field] ? 1 : -1;

/* use with .filter to get unique values */
export const unique = <T>(x: T, i: number, a: T[]): boolean =>
  a.indexOf(x) === i;

export const ORG_ID_REGEXP = new RegExp(
  `^/organisation/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}`
);

export const prependOrgIdToPath = (orgId: string | null, path = ""): string => {
  if (!orgId) return path;
  const pathWithOrgId =
    path && ORG_ID_REGEXP.test(path) ? path : `/organisation/${orgId}${path}`;
  return pathWithOrgId.replace(/\/$/, "");
};

// Used to remove typename property from objects
const isFile = (value: any): boolean =>
  (typeof File !== "undefined" && value instanceof File) ||
  (typeof Blob !== "undefined" && value instanceof Blob);

// https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-659596763
const omitDeep = (value: any, key: string): any => {
  if (Array.isArray(value)) {
    return value.map((i) => omitDeep(i, key));
  }
  if (typeof value === "object" && value !== null && !isFile(value)) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k === key) return newObject;
      return { [k]: omitDeep(value[k], key), ...newObject };
    }, {});
  }
  return value;
};

/* strip __typename property from gql result deeply */
export const cleanGQL = <T>(data: T): T => omitDeep(data, "__typename");

export const normalizeToStep = (value: number, step: number) => {
  // grabs the significant digits after the dot in our step size
  const sigfigs = String(step).replace(/(^\d*\.*)/g, "").length;
  const extraPrecision = 10 ** sigfigs;
  return Math.round(value * extraPrecision) / extraPrecision;
};

/**
 * Get 36 months ago iso date string
 * returns {string}
 */
export const getFromDate = () => {
  const fromDate = new Date();
  fromDate.setHours(0);
  fromDate.setMinutes(0);
  fromDate.setSeconds(0);
  fromDate.setMilliseconds(0);
  fromDate.setMonth(fromDate.getMonth() - 36);
  return fromDate.toISOString();
};
