import { mixpanel, apolloSpy } from "@shortlyster/analytics-kit";

type EventData = apolloSpy.EventData;

const trackSignUp = (event: EventData) => {
  const { variables: { email = undefined } = {} } = event;
  mixpanel.track("Submit Recruiter Sign Up", { email });
};

const trackAddNote = (event: EventData) => {
  const {
    variables: {
      note: { attachmentIds = undefined, message = undefined } = {},
    } = {},
  } = event;
  const messageText = message.replace(
    /<p>|<\/p>|<br>|<strong>|<\/strong>|<em>|<\/em>|<li>|<\/li>|<\/ol>|<\/ol>|\n/g,
    ""
  );

  mixpanel.track("Add note", {
    filesAttached: attachmentIds && attachmentIds.length,
    messageLength: messageText.length,
    richTextUsed: !!message.match(/<strong>|<em>|<li>|<ol>/),
  });
};

const trackInviteApplicant = (event: any) => {
  const {
    variables: { id },
  } = event;
  mixpanel.track("Invited Applicant", { applicantId: id });
};

const trackDropApplicant = (event: any) => {
  const {
    variables: { id },
  } = event;
  mixpanel.track("Drop Applicant", { applicantId: id });
};

const trackHireApplicant = (event: any) => {
  const {
    variables: { id, listingId },
  } = event;
  mixpanel.track("Hired Applicant", { applicantId: id, listingId });
};

// maps mutation name to event generation function
const mapping = {
  SignUpRecruiter: trackSignUp,
  AddNote: trackAddNote,
  DropApplicant: trackDropApplicant,
  HireApplicant: trackHireApplicant,
  InviteApplicant: trackInviteApplicant,
};

export default mapping;
