import { createContext } from "@compono/ui";

export type ApolloClientsContext = {
  employerClient: ReturnType<typeof useApolloClient>;
  engageClient: ReturnType<typeof useApolloClient>;
  shortlysterClient: ReturnType<typeof useApolloClient>;
  trackyrClient: ReturnType<typeof useApolloClient>;
  federationGatewayClient: ReturnType<typeof useApolloClient>;
};

const [useApolloClients, ApolloClientsContext] =
  createContext<ApolloClientsContext>({
    name: "ApolloClients",
  });

export { useApolloClients, ApolloClientsContext };
