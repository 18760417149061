import React, { useMemo } from "react";
import {
  AppState,
  Auth0Provider as Provider,
  useAuth0,
  User,
} from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

import { useRouteOrgId } from "~/hooks/useRouteOrgId";

export type AuthenticationProviderProps = {
  children: React.ReactNode;
  domain: string;
  clientId: string;
  envName: string;
  onRedirectCallback?: (appState?: AppState) => void;
};

/*
  AuthenticationProvider is a wrapper around Auth0Provider
  We use it:
    - to configure Auth0
    - as a facade to Auth0 to decouple our code from Auth0
    - customise the data format available via our useAuthentication hook
*/
export const AuthenticationProvider = (props: AuthenticationProviderProps) => {
  const { children, clientId, domain, envName, onRedirectCallback } = props;
  const history = useHistory();
  /**
   * Take users back to the route they intended to access before authentication
   */
  const defaultOnRedirectCallback = (appState?: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Provider
      domain={domain}
      clientId={clientId}
      connection={`compono-connection-${envName}`}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback || defaultOnRedirectCallback}
      audience="compono-api"
      scope="read:all"
    >
      {children}
    </Provider>
  );
};

/*
  copied from services/shortlyster/service/src/utils/user.ts getActiveOrgs
  which is used by the `currentOrganisation` resolver in services/shortlyster/service/src/modules/organisations/resolvers.ts
*/
const getActiveOrgs = (user: User) =>
  user.accesses?.organisations
    ?.filter((org: { deactivated: boolean }) => org.deactivated !== true)
    ?.filter(Boolean) || [];

type GetCurrentOrgIdArg = {
  requestedOrgId?: string;
  userAppData: User;
};

/*
  getCurrentOrgId returns an organisation id to use in data requests.

  If the org id specified in the url is valid then we use that, otherwise we
  use the first org id available from userAppData.
*/
const getCurrentOrgId = (arg: GetCurrentOrgIdArg) => {
  const { requestedOrgId, userAppData } = arg;

  const orgIds = getActiveOrgs(userAppData).map(
    (org: { organisationId: string }) => org.organisationId
  );

  const defaultOrgId = orgIds?.[0];
  const requestedOrgIdIsValid =
    requestedOrgId && orgIds.includes(requestedOrgId);

  return requestedOrgIdIsValid ? requestedOrgId : defaultOrgId;
};

export const useAuthentication = () => {
  const {
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0();
  const { orgId: requestedOrgId } = useRouteOrgId();

  // feels like this "url" should come from env/config
  const userAppData = useMemo(
    () => user?.["https://shortlyster.com/app_metadata"],
    [user]
  );

  const orgId = useMemo(
    () =>
      userAppData
        ? getCurrentOrgId({ requestedOrgId, userAppData })
        : undefined,
    [requestedOrgId, userAppData]
  );

  return {
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    user: user ? { id: userAppData?.userId } : undefined, // deprecate?
    userId: userAppData?.userId,
    orgId,
  };
};
