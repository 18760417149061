import { MainNavMenuAsButton, MainNavMenuLink } from "@compono/ui";
import { ChartOutlineIcon } from "../icons/ChartOutlineIcon";

export const ReportsNav = () => {
  return (
    <MainNavMenuAsButton icon={ChartOutlineIcon} label="Reports">
      <MainNavMenuLink href="/reports" label="Recruitment" />
    </MainNavMenuAsButton>
  );
};
