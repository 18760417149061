import React, { ReactNode } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { useEnsureOrgIdPath } from "../hooks/useEnsureOrgIdPath";

export const EnsureLocationOrgId = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { pathname, search } = location;

  const path = useEnsureOrgIdPath(pathname);

  if (path !== pathname) {
    return <Redirect to={`${path}${search}`} />;
  }

  return <>{children}</>;
};
