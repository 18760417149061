import { useUser } from "~/auth-layer";
import { Organisation, User } from "../../../lib/auth/types";

type Option = {
  label: string;
  value: string;
};

const useOrgs = () => {
  const [user] = useUser<User>();
  const { organisations = [] } = user!;

  const options: Option[] = organisations.map((org: Organisation) => ({
    value: org.id,
    label: org.name,
  }));

  return { options };
};

export default useOrgs;
