import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { useApolloClients } from "~/components/ApolloClientsProvider";
import { useOrganisation } from "~/auth-layer";

export type MessagesCount = {
  id: string;
  total: number;
  unread: number;
};

type Counts = MessagesCount & {
  listings: Array<
    MessagesCount & {
      conversations: number;
      applications: MessagesCount[];
    }
  >;
};

export type CountsResponse = {
  employerMessagesCounts: Counts;
};

type ApplicationsLookup = {
  [listingId: string]: MessagesCount;
};

type ListingsLookup = {
  [listingId: string]: MessagesCount & { conversations: number };
};

export type CountsLookup = {
  total: number;
  unread: number;
  listings?: ListingsLookup;
  applications?: ApplicationsLookup;
};

export const FetchMessagesCounts = gql`
  query FetchMessagesCounts($organisationId: ID!) {
    employerMessagesCounts(organisationId: $organisationId) {
      id
      total
      unread
      listings {
        id
        total
        unread
        conversations
        applications {
          id
          total
          unread
        }
      }
    }
  }
`;

export function toLookup(counts: Counts): CountsLookup {
  const { total, unread, listings } = counts;
  const listingsLookup: ListingsLookup = {};
  const applicationsLookup: ApplicationsLookup = {};
  listings.forEach((listing) => {
    const {
      id,
      total: lTotal,
      unread: lUnread,
      conversations,
      applications,
    } = listing;
    listingsLookup[listing.id] = {
      id,
      total: lTotal,
      unread: lUnread,
      conversations,
    };
    applications.forEach((appl) => {
      const { id: aId, total: aTotal, unread: aUnread } = appl;
      applicationsLookup[appl.id] = { id: aId, total: aTotal, unread: aUnread };
    });
  });
  return {
    total,
    unread,
    listings: listingsLookup,
    applications: applicationsLookup,
  };
}

const useFetchCounts = () => {
  const { trackyrClient } = useApolloClients();
  const [currentOrg] = useOrganisation();
  const organisationId = currentOrg && currentOrg.id;
  const { data, loading, error, refetch } = useQuery<CountsResponse>(
    FetchMessagesCounts,
    {
      client: trackyrClient,
      fetchPolicy: "cache-and-network",
      pollInterval: 60000,
      variables: { organisationId },
    }
  );
  const counts = data?.employerMessagesCounts
    ? toLookup(data.employerMessagesCounts)
    : undefined;
  return { counts, loading, error, refetch };
};

export default useFetchCounts;
