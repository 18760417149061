import * as React from "react";
import { IconProps } from "./types";
export const ChartOutlineIcon = ({ title, ...props }: IconProps) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    data-ui-icon
    {...props}
  >
    {title ? <title>{title}</title> : null}
    <path
      d="M5.75 4a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM10 9.25a.75.75 0 01.75.75v9.25h1.5V7a.75.75 0 011.5 0v12.25h1.5V12a.75.75 0 011.5 0v7.25H20a.75.75 0 010 1.5H7A2.75 2.75 0 014.25 18V8a.75.75 0 011.5 0v10c0 .69.56 1.25 1.25 1.25h2.25V10a.75.75 0 01.75-.75z"
      fill="currentColor"
    />
  </svg>
);
