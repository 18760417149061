import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

import { getRequestId } from "@compono/sentry-browser";
import { apolloSpy } from "@shortlyster/analytics-kit";

import { env } from "~/env";
import { useAuthentication } from "~/hooks/useAuthentication";
import { graphqlErrorLink } from "~/utils/graphqlErrorLink";

import { watcher } from "../mixpanel";
import customFetch from "./customFetch";
import resolvers from "./resolvers";
import typeDefs from "./schema";

export const apolloClientWithAuth0 = () => {
  const uri = `${env.EMPLOYER_API_URL}/graphql`;
  const { isAuthenticated, getAccessTokenSilently, orgId } =
    useAuthentication();

  const authLink = setContext(async (_, { headers, ...auth }) => {
    const token = isAuthenticated ? await getAccessTokenSilently() : null;
    return {
      ...auth,
      headers: {
        ...headers,
        ...(orgId && { "x-organisation-id": orgId }),
        ...(token && { authorization: `Bearer ${token}` }),
        "x-request-id": getRequestId(),
      },
    };
  });

  const uploadLink = createUploadLink({ uri, fetch: customFetch as any });

  const cache = new InMemoryCache();

  const baseClient = new ApolloClient({
    link: ApolloLink.from([graphqlErrorLink, authLink, uploadLink as any]),
    cache,
    resolvers,
    typeDefs,
  });

  apolloSpy.configure({ watcher });
  const client = apolloSpy.patch(baseClient);

  return client;
};
