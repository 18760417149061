import { Layer } from "grommet";
import ReactDOM from "react-dom";

import { initSentry } from "@compono/sentry-browser";
import { ga, gtm, mixpanel } from "@shortlyster/analytics-kit";
import * as zendesk from "@shortlyster/zendesk-kit";

import { env } from "~/env";
import App from "./App";

const { ENVIRONMENT, GITHUB_SHA = "", SENTRY_DSN } = env;

const SoCringeAvoidTreeShakingGrommetLayerUsedInUiKit = () => (
  <Layer full={false} modal={false} style={{ display: "none" }} />
);

ReactDOM.render(
  <>
    <SoCringeAvoidTreeShakingGrommetLayerUsedInUiKit />
    <App />
  </>,
  document.getElementById("root")
);

if (env.APP_RUN_SENTRY) {
  initSentry({ ENVIRONMENT, RELEASE: GITHUB_SHA, SENTRY_DSN });
}

if (env.APP_SEND_ANALYTICS && !env.HEADLESS_TESTING) {
  ga.init();
  gtm.init();

  // override the mixpanel lib url
  if (env.MIXPANEL_CDN_URL) {
    window.MIXPANEL_CUSTOM_LIB_URL = env.MIXPANEL_CDN_URL;
  }

  // we want to remove this initalization of mixpanel eventually,
  // but we need to make sure everything it inits is brought over
  // and updated first. it does not conflict with @compono/analytics
  mixpanel.configure({ trackPageViews: true, debug: false });
  mixpanel.init(env.MIXPANEL_TOKEN, {
    api_host: env.MIXPANEL_API_URL
  });
}

if (env.APP_RUN_ZENDESK) {
  zendesk.init(env.ZENDESK_TOKEN, {}, env.ZENDESK_TOKENURL, "init");
}
