import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";

import { getRequestId } from "@compono/sentry-browser";
import { apolloSpy } from "@shortlyster/analytics-kit";

import { useAuthentication } from "~/hooks/useAuthentication";
import { graphqlErrorLink } from "~/utils/graphqlErrorLink";

import { watcher } from "../mixpanel";
import customFetch from "./customFetch";

export type UseApolloClientProps = {
  uri: string;
  orgId?: string;
};

export const apolloClientWithAuth0 = ({ uri, orgId }: UseApolloClientProps) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuthentication();

  const authLink = setContext(async (_, { headers, ...auth }) => {
    const token = isAuthenticated ? await getAccessTokenSilently() : null;
    return {
      ...auth,
      headers: {
        ...headers,
        ...(token && { authorization: `Bearer ${token}` }),
        ...(orgId && { "x-organisation-id": orgId }),
        "x-request-id": getRequestId(),
      },
    };
  });

  // https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/#breaking-cache-changes
  // https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
  const cache = new InMemoryCache({
    possibleTypes: {
      Answer: ["FloatAnswer", "StringAnswer"],
      Feed: ["ChangeLog", "Note"],
    },
  });

  const uploadLink = createUploadLink({ uri, fetch: customFetch });

  const baseClient = new ApolloClient({
    link: ApolloLink.from([graphqlErrorLink, authLink, uploadLink as any]),
    cache,
  });

  apolloSpy.configure({ watcher });

  const client = apolloSpy.patch(baseClient);
  return client;
};
